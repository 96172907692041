angular.module('mineXplore')
	.constant('config', {
		apiUrl: 'https://api.minexplore.com.br',
		apiUrl: 'https://api.minexplore.com.br',
		baseUrl: '/api/v2',
		enableDebug: true,
		mapbox_api_key: 'pk.eyJ1IjoibWluZXhwbG9yZSIsImEiOiJja2tzZjZ0NjAxMGowMndueHEyc2NzY28zIn0.1iBFPnYVjX-XCRcpuhjKoA'
	});


