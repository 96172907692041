'use strict';
angular.module('mineXplore')
  .controller('PrjPrazosController',
    ['$scope', 'Project', 'ProjectsTerms', '$state', 'CalendarHelper', function ($scope, Project, Terms, $state, cal) {
      $scope.project_id = $state.params.id;
      Project.get({ id: $state.params.id },
        function (response) {
          console.log(response);
          $scope.project = response.data;
        },
        function (error) {
          console.log(error);
        });
      var load_data = function () {
        $scope.project = { id: $state.params.id };
        var params = { id: $state.params.id, dt_ini: cal.dt_ini.get(), dt_end: cal.dt_end.get() };
        Terms.query(params,
          function (response) {
            console.log(response);
            $scope.data = response.data;
          },
          function (error) {
            console.log(error);
          });
      };
      $scope.load_data = load_data;
    }
    ]
  )
  ;
